<div class="flex items-center justify-between">
  <h4>{{ isNew ? "Create new Project" : "Edit " + project()?.name }}</h4>
  <button
    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-md text-gray-500 outline-gray-500 transition-colors hover:bg-gray-500/10 hover:text-gray-600 active:bg-gray-500/25 active:text-gray-700"
    (click)="hndClose()"
  >
    <ng-icon name="tablerX" size="20" strokeWidth="2"></ng-icon>
  </button>
</div>

<ng-container [ngTemplateOutlet]="templateBasicInformation"></ng-container>

<div class="mt-auto flex justify-between gap-6">
  <button sButton size="medium" type="tertiary" (click)="hndClose()">
    Cancel
  </button>

  <button
    sButton
    size="medium"
    type="primary"
    [disabled]="form.invalid || !form.dirty"
    (click)="hndSave()"
  >
    {{ isNew ? "Create project" : "Update project" }}
  </button>
</div>

<ng-template #templateBasicInformation>
  <div class="grid grid-cols-3 gap-x-6 gap-y-4" [formGroup]="form">
    <div class="col-span-1 flex flex-col gap-2">
      <h6>Prefix*</h6>
      <input
        sFocus
        nz-input
        placeholder="Enter a prefix"
        type="text"
        formControlName="alt_prefix"
        nzSize="large"
        maxlength="10"
      />
    </div>
    <div class="col-span-2 flex flex-col gap-2">
      <h6>Name*</h6>
      <input
        sFocus
        nz-input
        placeholder="Enter the name of your project"
        type="text"
        formControlName="name"
        nzSize="large"
      />
    </div>

    <div class="col-span-3 flex flex-col gap-2">
      <h6>Description</h6>
      <textarea
        nz-input
        placeholder="Enter a brief description of your project"
        [nzAutosize]="{ minRows: 3, maxRows: 6 }"
        formControlName="description"
        nzSize="large"
      ></textarea>
    </div>

    <div class="col-span-3 flex flex-col gap-2">
      <h6>Manager*</h6>
      <nz-select
        nzShowSearch
        nzAllowClear
        nzPlaceHolder="Select manager"
        formControlName="manager_id"
        nzSize="large"
      >
        @for (user of users$ | async; track user.id) {
          <nz-option [nzLabel]="user.full_name" [nzValue]="user.id">
          </nz-option>
        }
      </nz-select>
    </div>
    <div class="col-span-2 flex flex-col gap-2">
      <h6>Methodology</h6>
      <nz-select formControlName="methodology" nzSize="large">
        <nz-option nzValue="kanban" nzLabel="Kanban"></nz-option>
        <nz-option nzValue="scrum" nzLabel="Scrum"></nz-option>
      </nz-select>
    </div>
    <div class="flex flex-col gap-2">
      <h6>Budget</h6>
      <nz-input-number-group
        class="w-full"
        [nzPrefix]="(workspace$ | async)?.currency?.symbol"
      >
        <nz-input-number
          class="text-right"
          [nzStep]="1"
          formControlName="budget"
          nzPlaceHolder="0"
          nzSize="large"
          [nzMin]="0"
        ></nz-input-number>
      </nz-input-number-group>
    </div>
  </div>
</ng-template>

<ng-template #templateAdditional> </ng-template>
