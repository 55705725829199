import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { inject, Injectable, Type } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { AbstractSideDialogComponent } from '../classes/abstract-side-dialog-component';
import { SideDialogType } from '../types/side-dialog.types';

@Injectable({
  providedIn: 'root',
})
export class SideDialogService {
  //#region Injectables
  private overlay = inject(Overlay);
  private dialogService = inject(Dialog);
  //#endregion

  //#region Data Variables
  private viewDialogs: DialogRef<any, Type<AbstractSideDialogComponent>>[] = [];
  //#endregion

  expandSideDialog(expand: boolean = true) {
    const dialog = this.viewDialogs.at(this.viewDialogs.length - 1);
    if (!dialog) {
      return;
    }

    this.expandSideDialogByRef(dialog, expand);
  }

  expandSideDialogByRef(
    dialogRef: DialogRef<any, any>,
    expand: boolean = true,
  ) {
    const toolbarWidthWithMargin = '44px';
    const sidebarWidth = '220px';

    if (expand) {
      // TODO: fix this hardcoded value
      dialogRef?.overlayRef.updateSize({
        width: `calc(100% - (${sidebarWidth} + ${toolbarWidthWithMargin}))`,
      });
    } else {
      dialogRef?.overlayRef.updateSize({ width: undefined });
    }
  }

  public async openSideDialog(type: SideDialogType, data: any): Promise<any> {
    const dialogComponentClass = await this.getComponentClassByDialogType(type);
    if (!dialogComponentClass) {
      return false;
    }

    /*
      viewDialogs holds all the open dialogs
      animate is set to false when trying to open a dialog while another is open
      so we create one on top of the other without animation and then close the previous one
      for seamless transition
    */

    let animate = true;
    const positionStrategy = this.overlay
      .position()
      .global()
      .right('44px') // 44px toolbar width
      .top('3.5rem'); // 3.5rem topbar height

    if (this.viewDialogs.length) {
      animate = false;
    }

    const dialogPanelClasses = ['cdk-task-overlay-pane--expandable'];
    const dialog = this.dialogService.open<any, any, any>(
      dialogComponentClass,
      {
        data,
        height: 'calc(100% - 3.5rem)',
        panelClass: animate
          ? [
              ...dialogPanelClasses,
              'slide-in-from-right',
              'animate-in',
              'fade-in',
            ]
          : dialogPanelClasses,
        positionStrategy,
        hasBackdrop: false,
        closeOnNavigation: true,
        closeOnDestroy: true,
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      },
    );

    dialog.closed.subscribe({
      next: (value) => {
        this.viewDialogs = this.viewDialogs.filter((d) => d !== dialog);
      },
    });

    dialog.outsidePointerEvents.subscribe((event) => {
      // TODO: refactor using more proper way, like in Asana
      if (!event.target) {
        return;
      }

      const target = event.target as HTMLElement;

      if (
        target.closest('app-topbar') ||
        target.closest('app-toolbar') ||
        target.closest('app-board-task') ||
        target.closest('.cdk-overlay-container')
      ) {
        return;
      }

      dialog.close();
    });

    this.viewDialogs.forEach((dialog) => dialog.close());

    this.viewDialogs.push(dialog);

    return await lastValueFrom(dialog?.closed.pipe(take(1)));
  }

  private async getComponentClassByDialogType(
    type: SideDialogType,
  ): Promise<Type<AbstractSideDialogComponent> | null> {
    switch (type) {
      case 'task':
        const { ViewTaskDialogComponent } = await import(
          '../shared/components/dialogs/view-task-dialog/view-task-dialog.component'
        );
        return ViewTaskDialogComponent;
      case 'epic':
        const { ViewEpicDialogComponent } = await import(
          '../shared/components/dialogs/view-epic-dialog/view-epic-dialog.component'
        );
        return ViewEpicDialogComponent;
      case 'dashboard-user-widget':
        const { DashboardWidgetDialogComponent } = await import(
          '../shared/components/dialogs/dashboard-widget-dialog/dashboard-widget-dialog.component'
        );
        return DashboardWidgetDialogComponent;
    }

    return null;
  }
}
