import { Enums } from '@solid/types/supabase';

export const DEFAULT_METHODOLOGY: Enums<'agile_methodology'> = 'kanban';
export const DEFAULT_PROJECT_COLORS: string[] = [
  '#44bba4',
  '#ff99c5',
  '#3a86ff',
  '#fd9a6a',
  '#ffd86d',
  '#a6c8fe',
  '#92dc87',
  '#b588f4',
  '#297062',
  '#cc0058',
  '#c94506',
  '#f59e0b',
  '#2353a0',
  '#4a8541',
  '#692dbd',
  '#737077',
];
