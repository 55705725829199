@if (loading()) {
  <div
    class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white text-primary"
  >
    <ng-icon
      class="animate-spin"
      name="tablerLoader2"
      size="32"
      strokeWidth="2"
    ></ng-icon>
  </div>
}

<div class="flex items-center justify-between">
  @if (isNew()) {
    <h4>Create new Epic</h4>
  } @else {
    <h4>Edit {{ currentEpicName() }}</h4>
  }
  <button
    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-md text-gray-500 outline-gray-500 transition-colors hover:bg-gray-500/10 hover:text-gray-600 active:bg-gray-500/25 active:text-gray-700"
    (click)="hndClose()"
  >
    <ng-icon name="tablerX" size="20" strokeWidth="2"></ng-icon>
  </button>
</div>

<ng-container [ngTemplateOutlet]="templateBasicInformation"></ng-container>

<div class="mt-auto flex justify-between gap-6">
  <button sButton type="tertiary" size="medium" (click)="hndClose()">
    Cancel
  </button>
  <button
    sButton
    size="medium"
    type="primary"
    [disabled]="form.invalid || !form.dirty"
    (click)="hndSave()"
  >
    Save
  </button>
</div>

<ng-template #templateBasicInformation>
  <div class="grid grid-cols-6 gap-4" [formGroup]="form">
    @if (!hasProjectId()) {
      <div class="col-span-full flex flex-col gap-2">
        <h6>Project*</h6>
        <ng-container
          *ngxPermissionsOnly="
            ['admin'];
            then templateAdminProject;
            else templateProject
          "
        ></ng-container>

        <ng-template #templateAdminProject>
          <nz-select
            nzPlaceHolder="Select a project"
            formControlName="project_id"
            nzSize="large"
          >
            @for (project of projects(); track project.id) {
              <nz-option
                [nzValue]="project.id"
                [nzLabel]="project.name"
              ></nz-option>
            }
          </nz-select>
        </ng-template>

        <ng-template #templateProject>
          <nz-select
            nzPlaceHolder="Select a project"
            formControlName="project_id"
            nzSize="large"
          >
            @for (project of managedProjects(); track project.id) {
              <nz-option
                [nzValue]="project.id"
                [nzLabel]="project.name"
              ></nz-option>
            }
          </nz-select>
        </ng-template>
      </div>
    }

    <div class="col-span-4 flex flex-col gap-2">
      <h6>Name*</h6>
      <input
        sFocus
        nz-input
        placeholder="Enter the name of the epic"
        type="text"
        formControlName="name"
        nzSize="large"
      />
    </div>
    <div class="col-span-2 flex flex-col gap-2">
      <h6>Priority</h6>
      <nz-select
        nzPlaceHolder="Select a priority"
        nzSize="large"
        formControlName="priority"
      >
        <nz-option nzLabel="High" nzValue="high"></nz-option>
        <nz-option nzLabel="Medium" nzValue="medium"></nz-option>
        <nz-option nzLabel="Low" nzValue="low"></nz-option>
      </nz-select>
    </div>

    <div class="col-span-full flex flex-col gap-2">
      <h6>Description</h6>
      <textarea
        nz-input
        class="resize-none"
        placeholder="Enter a brief description of your epic"
        [rows]="6"
        formControlName="description"
        nzSize="large"
      ></textarea>
    </div>
    <div class="col-span-3 flex flex-col gap-2">
      <h6>Start date</h6>
      <nz-date-picker
        formControlName="start_at"
        nzSize="large"
        nzPlaceHolder="Select start date"
      ></nz-date-picker>
    </div>
    <div class="col-span-3 flex flex-col gap-2">
      <h6>Due date</h6>
      <nz-date-picker
        [nzDisabledDate]="isDateDisabled"
        formControlName="end_at"
        nzSize="large"
        nzPlaceHolder="Select due date"
      ></nz-date-picker>
    </div>
  </div>
</ng-template>
