import { inject, Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { from, map } from 'rxjs';
import { Tables } from '@solid/types/supabase';
import { DetailedEpic, Epic } from '@solid/types/project.types';
import {
  SELECT_DETAILED_VIEW_EPIC,
  SELECT_VIEW_EPIC,
} from '@solid/constants/query.constant';
import { Dialog } from '@angular/cdk/dialog';
import {
  CreateEpicDialogComponent,
  ICreateEpicDialogData,
} from '../shared/components/dialogs/create-epic-dialog/create-epic-dialog.component';
import { DEFAULT_PANEL_CLASSES } from '@solid/constants/dialog.constant';
import { SideDialogService } from './side-dialog.service';
import { IViewEpicDialogData } from '../shared/components/dialogs/view-epic-dialog/view-epic-dialog.component';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';

@Injectable({
  providedIn: 'root',
})
export class EpicService {
  //#region Injections
  private dialogService = inject(Dialog);
  private supabase = inject(SupabaseService);
  private sideDialogService = inject(SideDialogService);
  //#endregion

  //#region Constants
  private TABLE = 'company_project_epics';
  //#endregion

  constructor() {}

  //#region Public Methods
  get(epicId: number) {
    return from(
      this.supabase.client
        .from(this.TABLE)
        .select(SELECT_VIEW_EPIC)
        .eq('id', epicId)
        .single(),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as Epic;
      }),
    );
  }

  getDetailed(epicId: number) {
    return from(
      this.supabase.client
        .from(this.TABLE)
        .select(SELECT_DETAILED_VIEW_EPIC)
        .eq('id', epicId)
        .single(),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as DetailedEpic;
      }),
    );
  }

  getByProjectId(projectId: number) {
    return from(
      this.supabase.client
        .from(this.TABLE)
        .select(SELECT_VIEW_EPIC)
        .eq('project_id', projectId),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as Epic[];
      }),
    );
  }

  getDetailedByProjectId(projectId: number) {
    return from(
      this.supabase.client
        .from(this.TABLE)
        .select(SELECT_DETAILED_VIEW_EPIC)
        .eq('project_id', projectId),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as DetailedEpic[];
      }),
    );
  }

  add(epic: Partial<Tables<'company_project_epics'>>) {
    delete epic.id;
    console.count('add epic');

    return from(
      this.supabase.client
        .from(this.TABLE)
        .insert(epic)
        .select(SELECT_VIEW_EPIC)
        .single(),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as Epic;
      }),
    );
  }

  update(epic: Partial<Tables<'company_project_epics'>>) {
    return from(
      this.supabase.client
        .from(this.TABLE)
        .update(epic)
        .eq('id', epic.id)
        .select(SELECT_VIEW_EPIC)
        .single(),
    ).pipe(
      map((response) => {
        if (!response.data) return null;

        return response.data as Epic;
      }),
    );
  }

  updateCost(epicId: number, cost: number) {
    return from(
      this.supabase.client.from(this.TABLE).update({ cost }).eq('id', epicId),
    );
  }

  updateEffort(epicId: number, effort: number) {
    return from(
      this.supabase.client.from(this.TABLE).update({ effort }).eq('id', epicId),
    );
  }

  updateFte(epicId: number, fte: number) {
    return from(
      this.supabase.client.from(this.TABLE).update({ fte }).eq('id', epicId),
    );
  }

  remove(epicId: number) {
    return from(
      this.supabase.client.from(this.TABLE).delete().eq('id', epicId),
    );
  }

  getRealTime({
    channel,
    filter,
    callback,
  }: {
    channel: string;
    callback: (
      payload: RealtimePostgresChangesPayload<Tables<'company_project_epics'>>,
    ) => void;
    filter?: string;
  }) {
    return this.supabase.client.channel(channel).on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'company_project_epics',
        filter: filter,
      },
      callback,
    );
  }
  //#endregion

  //#region Dialogs
  showCreateDialog(projectId: number | undefined = undefined) {
    const data: ICreateEpicDialogData = {
      projectId,
    };

    const dialog = this.dialogService.open(CreateEpicDialogComponent, {
      data,
      panelClass: DEFAULT_PANEL_CLASSES,
      width: '552px',
    });

    dialog.closed.subscribe({
      next: (result) => {
        if (!result) return;
      },
    });
  }

  showEditDialog(epicId: number) {
    const data: ICreateEpicDialogData = {
      epicId,
    };

    const dialog = this.dialogService.open(CreateEpicDialogComponent, {
      data,
      panelClass: DEFAULT_PANEL_CLASSES,
      width: '552px',
    });

    dialog.closed.subscribe({
      next: (result) => {
        if (!result) return;
      },
    });
  }

  showSidebar(epicId: number) {
    const data: IViewEpicDialogData = {
      epicId,
    };

    this.sideDialogService.openSideDialog('epic', data);
  }
  //#endregion
}
