import { Dialog } from '@angular/cdk/dialog';
import { inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Profile } from '@solid/types/queries.types';
import {
  RealtimeChannel,
  RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  from,
  map,
  Observable,
  skipWhile,
  take,
  tap,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DEFAULT_PANEL_CLASSES } from '../constants/dialog.constant';
import {
  SELECT_PROJECT_TEAM,
  SELECT_VIEW_PROJECT,
} from '../constants/query.constant';
import {
  ConfirmDialogComponent,
  IConfirmDialogData,
} from '../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  CreateEpicDialogComponent,
  ICreateEpicDialogData,
} from '../shared/components/dialogs/create-epic-dialog/create-epic-dialog.component';
import {
  CreateProjectDialogComponent,
  ICreateProjectDialogData,
} from '../shared/components/dialogs/create-project-dialog/create-project-dialog.component';
import {
  ITeamDialogData,
  TeamDialogComponent,
} from '../shared/components/dialogs/team-dialog/team-dialog.component';
import {
  WorkspaceProject,
  CreateProjectPayload,
  DetailedEpic,
  Epic,
} from '../types/project.types';
import { Tables } from '../types/supabase';
import ServiceUtils from '../utils/service.utils';
import { WorkspaceService } from './workspace.service';
import { EpicService } from './epic.service';
import { PreferencesService } from './preferences.service';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  //#region Injections
  private router = inject(Router);
  private dialogService = inject(Dialog);
  private supabase = inject(SupabaseService);
  private workspaceService = inject(WorkspaceService);
  private preferencesService = inject(PreferencesService);
  private epicService = inject(EpicService);
  private authService = inject(AuthService);
  private permissionsService = inject(NgxPermissionsService);
  //#endregion

  //#region Control Variables
  loadingProject = signal(true);
  //#endregion

  //#region Observables
  private onProjectsChange$ = new BehaviorSubject<WorkspaceProject[]>([]);
  private onProjectsLoading$ = new BehaviorSubject<boolean>(false);
  private onProjectChange$ = new BehaviorSubject<WorkspaceProject | null>(null);
  private onSubscriptionsChange$ = new BehaviorSubject<RealtimeChannel[]>([]);
  private onUsersChange$ = new BehaviorSubject<Profile[]>([]);
  private onEpicChanges$ = new BehaviorSubject<DetailedEpic[]>([]);

  private projectChange$ = this.onProjectChange$.pipe(
    skipWhile((_) => !_),
    debounceTime(500),
    tap((project) => {
      this.removeEpicsRealTimeChannel();
      if (!project) return;
      this.epicsRealTimeChannel = this.getEpicsRealTimeChannel(project.id);
    }),
    tap((project) => {
      this.removeProjectRealTimeChannel();
      if (!project) return;
      this.projectRealTimeChannel = this.getProjectRealTimeChannel(project.id);
    }),
    tap((project) => {
      if (!project) return;
      this.refreshTeamUsers(project.id);

      this.epicService
        .getDetailedByProjectId(project.id)
        .pipe(catchError((error) => from([])))
        .subscribe({
          next: (epics) => {
            if (!epics) return;
            this.onEpicChanges$.next(epics);
          },
        });
      this.preferencesService.setLastProjectId(project.id);
    }),
    tap(() => {
      this.loadingProject.set(false);
    }),
  );

  projectsLoading$ = this.onProjectsLoading$.asObservable();
  projects$ = this.onProjectsChange$.pipe(
    map((projects) => projects.sort((a, b) => a.name.localeCompare(b.name))),
  );
  projectPrefixesMap$ = this.onProjectsChange$.pipe(
    skipWhile((_) => !_ || _.length === 0),
    map((projects) => {
      return projects.reduce(
        (acc, project) => {
          acc[project.id] = project?.alt_prefix || '';
          return acc;
        },
        {} as { [key: number]: string },
      );
    }),
  );
  managedProjects$ = combineLatest([
    this.authService.user$,
    this.projects$,
  ]).pipe(
    skipWhile(([user, projects]) => projects.length == 0),
    map(([user, projects]) => {
      if (!user) return [];
      return projects.filter((project) => project.manager_id == user.id);
    }),
  );

  project$ = this.onProjectChange$.pipe(skipWhile((_) => !_));

  permissions$ = this.managedProjects$.pipe(
    tap((current) => {
      const prefix = 'project_manager_';
      const permissions = this.permissionsService.getPermissions();

      Object.keys(permissions).forEach((permission) => {
        if (permission.startsWith(prefix)) {
          this.permissionsService.removePermission(permission);
        }
      });

      const currentPermissions = current.map(
        (project) => `${prefix}${project.id}`,
      );
      this.permissionsService.addPermission(currentPermissions);

      console.log(this.permissionsService.getPermissions());
    }),
  );

  epics$ = this.onEpicChanges$.pipe(tap((epics) => epics.sort(this.sortEpics)));
  users$ = this.onUsersChange$.asObservable();
  //#endregion

  //#region Getters & Setters
  get projects() {
    return this.onProjectsChange$.value;
  }

  get project() {
    return this.onProjectChange$.value;
  }

  //#endregion

  //#region RealTime
  projectRealTimeChannel: RealtimeChannel | undefined;
  epicsRealTimeChannel: RealtimeChannel | undefined;
  //#endregion

  private sortEpics = (a: Epic, b: Epic) => {
    const aEndAt = a.end_at;
    const bEndAt = b.end_at;

    if (!aEndAt) return +1;
    if (!bEndAt) return -1;

    return new Date(aEndAt) < new Date(bEndAt) ? -1 : 1;
  };

  //#region Control Variables
  isInitialized = false;
  //#endregion

  constructor() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        const urlParts = url.split('/').filter((item) => Boolean(item));
        if (this.isProjectUrl(url)) {
          const currentProject = this.project;
          const projectId = urlParts.at(1);
          const projectIdNumber = Number(projectId);

          if (
            projectId &&
            !isNaN(projectIdNumber) &&
            (!currentProject || currentProject.id !== projectIdNumber)
          ) {
            this.loadProject(projectIdNumber);
          }

          return;
        }

        this.onProjectChange$.next(null);
      });

    // this.projectsChange.subscribe();
    this.projectChange$.subscribe();

    // TODO: fix for loading permissions
    this.managedProjects$.subscribe();
    this.permissions$.subscribe();

    this.workspaceService.workspace$
      .pipe(
        distinctUntilChanged(
          (oldWorkspace, newWorkspace) => oldWorkspace?.id == newWorkspace?.id,
        ),
        debounceTime(500),
      )
      .subscribe({
        next: (workspace) => {
          if (!workspace) return;
          if (this.isInitialized) {
            this.reset();
          }

          this.getProjects(workspace.id);
          this.initializeRealTime();

          this.isInitialized = true;
        },
      });
  }

  //#region Private Methods
  private initializeRealTime() {
    const subscription = this.supabase.client
      .channel('company_projects')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'company_projects',
        },
        this.handleRealTime.bind(this),
      )
      .subscribe();

    this.onSubscriptionsChange$.next([
      ...this.onSubscriptionsChange$.value,
      subscription,
    ]);
  }

  private getProjectRealTimeChannel(projectId: number) {
    return this.supabase.client
      .channel(`temp:project_${projectId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'company_projects',
          filter: `id=eq.${projectId}`,
        },
        this.handleProjectRealTimeChange.bind(this),
      )
      .subscribe();
  }

  private removeProjectRealTimeChannel() {
    if (!this.projectRealTimeChannel) return;
    this.projectRealTimeChannel.unsubscribe();
  }

  private getEpicsRealTimeChannel(projectId: number) {
    return this.supabase.client
      .channel(`temp:project_${projectId}_epics`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'company_project_epics',
        },
        this.handleEpicRealTimeChange.bind(this),
      )
      .subscribe();
  }

  private removeEpicsRealTimeChannel() {
    if (!this.epicsRealTimeChannel) return;
    this.epicsRealTimeChannel.unsubscribe();
  }

  private handleProjectRealTimeChange(
    payload: RealtimePostgresChangesPayload<Tables<'company_projects'>>,
  ) {
    const currentProject = this.onProjectChange$.value;
    if (!currentProject) {
      this.removeProjectRealTimeChannel();
      return;
    }
    const oldPayload = payload.old as Tables<'company_projects'>;
    const newPayload = payload.new as Tables<'company_projects'>;
    const eventType = payload.eventType;

    const isSameProject = currentProject.id == oldPayload.id;

    if (eventType === 'INSERT') return;

    // filter does not work with delete, so we need to check if the project is the same
    if (eventType === 'DELETE' && isSameProject) {
      this.router.navigate(['/', 'dashboard']);
      return;
    }

    if (eventType === 'UPDATE') {
      this.getProjectObservable(newPayload.id).subscribe({
        next: (response) => {
          if (!response.data) return;
          this.onProjectChange$.next(response.data);
        },
      });
    }
  }

  private handleEpicRealTimeChange(
    payload: RealtimePostgresChangesPayload<Tables<'company_project_epics'>>,
  ) {
    const currentProject = this.onProjectChange$.value;
    if (!currentProject) {
      this.removeEpicsRealTimeChannel();
      return;
    }

    const localEpics = this.onEpicChanges$.value;

    const oldPayload = payload.old as Tables<'company_project_epics'>;
    const newPayload = payload.new as Tables<'company_project_epics'>;
    const eventType = payload.eventType;
    const localEpic = localEpics.find((epic) => epic.id == oldPayload.id);
    const isFromThisProject = newPayload.project_id == currentProject.id;

    if (isFromThisProject && eventType === 'INSERT') {
      this.epicService.getDetailed(newPayload.id).subscribe({
        next: (epic) => {
          if (!epic) return;
          ServiceUtils.addLocalEntity(
            epic,
            this.onEpicChanges$,
            this.sortEpics,
          );
        },
      });
    } else if (isFromThisProject && eventType === 'UPDATE') {
      this.epicService.getDetailed(newPayload.id).subscribe({
        next: (epic) => {
          if (!epic) return;
          ServiceUtils.updateLocalEntity(
            epic,
            this.onEpicChanges$,
            (epic) => epic.id === newPayload.id,
          );
        },
      });
    } else if (eventType === 'DELETE' && localEpic != undefined) {
      ServiceUtils.removeLocalEntityByPredicate(
        this.onEpicChanges$,
        (epic) => epic.id === oldPayload.id,
      );
    }
  }

  private async handleRealTime(
    event: RealtimePostgresChangesPayload<Tables<'company_projects'>>,
  ) {
    let id: number | undefined;
    let project: WorkspaceProject | undefined;

    if (event.eventType == 'INSERT') {
      const id = event.new.id;
      const project = await this.getProject(id);
      if (!project) return;

      ServiceUtils.addLocalEntity(project, this.onProjectsChange$, (a, b) =>
        a.name.localeCompare(b.name),
      );
    } else if (event.eventType == 'UPDATE') {
      const id = event.new.id;
      const project = await this.getProject(id);
      if (!project) return;

      ServiceUtils.updateLocalEntity(
        project,
        this.onProjectsChange$,
        (project) => project.id === event.new.id,
      );
    } else if (event.eventType == 'DELETE') {
      const workspaceId = event.old.id;

      ServiceUtils.removeLocalEntityByPredicate(
        this.onProjectsChange$,
        (project) => project.id === workspaceId,
      );
    }

    ServiceUtils.sort(this.onProjectsChange$, (a, b) =>
      a.name.localeCompare(b.name),
    );
  }

  private updateTeamUsers(projectId: number, userIds: string[]) {
    return from(
      this.supabase.client.rpc('update_project_users', {
        request_project_id: projectId,
        request_user_ids: userIds,
      }),
    );
  }

  private reset() {
    this.resetData();
    this.resetRealTime();
  }

  private resetData() {
    this.onProjectChange$.next(null);
    this.onProjectsChange$.next([]);
  }

  private resetRealTime() {
    const subscriptions = this.onSubscriptionsChange$.value;

    if (subscriptions.length) {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    this.removeEpicsRealTimeChannel();
  }

  getUrlParts(url: string) {
    return url.split('/').filter((item) => item !== '');
  }

  isProjectUrl(url: string) {
    const urlParts = this.getUrlParts(url);
    const isProject = urlParts.at(0) === 'projects';
    const projectId = Number(urlParts.at(1));

    return isProject && !isNaN(projectId);
  }

  getReplacedUrl(url: string, projectId: number) {
    const isValidUrl = this.isProjectUrl(url);
    if (!isValidUrl) {
      throw new Error('Invalid project URL');
    }
    const urlParts = this.getUrlParts(url);
    urlParts[1] = projectId.toString();
    return urlParts.join('/');
  }
  //#endregion

  //#region Public Methods
  getRealTime({
    channel,
    callback,
    filter,
  }: {
    channel: string;
    callback: (
      payload: RealtimePostgresChangesPayload<Tables<'company_projects'>>,
    ) => void;
    filter?: string;
  }) {
    return this.supabase.client.channel(channel).on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'company_projects',
        filter: filter,
      },
      callback,
    );
  }
  getPrefixes() {
    return from(
      this.supabase.client.from('company_projects').select('alt_prefix'),
    ).pipe(
      map((response) => {
        if (!response || response.error || !response.data) return [];

        return response.data.map((project) => project.alt_prefix);
      }),
    );
  }

  async getProjects(companyId: number) {
    this.onProjectsLoading$.next(true);

    const { data, error } = await this.supabase.client
      .from('company_projects')
      .select(SELECT_VIEW_PROJECT)
      .eq('archived', false)
      .eq('company_id', companyId)
      .order('name');

    this.onProjectsLoading$.next(false);

    if (error) {
      console.error('Error getting projects:', error);
      return;
    }

    console.debug(
      '[ProjectService]: Loaded projects for company with id: ' + companyId,
      data,
    );
    this.onProjectsChange$.next(data as any[] as WorkspaceProject[]);
  }

  getProjectObservable(projectId: number) {
    return from(
      this.supabase.client
        .from('company_projects')
        .select(SELECT_VIEW_PROJECT)
        .eq('archived', false)
        .eq('id', projectId)
        .single(),
    );
  }

  async getProject(projectId: number) {
    const { data, error } = await this.supabase.client
      .from('company_projects')
      .select(SELECT_VIEW_PROJECT)
      .eq('archived', false)
      .eq('id', projectId)
      .single();

    if (error) {
      console.error('Error getting projects:', error);
      return;
    }

    return data as any as WorkspaceProject;
  }

  refreshTeamUsers(projectId: number) {
    this.getTeamUsers(projectId)
      .pipe(take(1))
      .subscribe({
        next: (users) => {
          this.onUsersChange$.next(users);
        },
      });

    return this;
  }
  refreshProjectEpics(projectId: number) {
    this.epicService
      .getDetailedByProjectId(projectId)
      .pipe(catchError((error) => from([])))
      .subscribe({
        next: (epics) => {
          if (!epics) return;
          this.onEpicChanges$.next(epics);
        },
      });
  }

  async create(payload: CreateProjectPayload) {
    const { data, error } = await this.supabase.client
      .from('company_projects')
      .insert(payload)
      .select(SELECT_VIEW_PROJECT)
      .single();

    if (error) {
      console.error('Error creating project:', error);
      return;
    }

    return data;
  }

  async update(payload: Partial<WorkspaceProject>) {
    // ! TODO: refactor, this is a mess
    // don't want to update
    delete payload.progress;
    delete payload.alt_last_id;
    delete payload.company_id;
    delete payload.updated_at;
    delete payload.created_at;

    // error if included
    delete payload.company;
    delete payload.manager;
    delete payload.team;
    delete payload.epics;

    const { data, error } = await this.supabase.client
      .from('company_projects')
      .update(payload)
      .eq('id', payload.id)
      .select(SELECT_VIEW_PROJECT)
      .single();

    if (error) {
      console.error('Error updating project', error);
      return;
    }

    return data;
  }

  async delete(projectId: number) {
    const { data, error } = await this.supabase.client
      .from('company_projects')
      .delete()
      .eq('id', projectId);

    if (error) {
      console.error('Error deleting project', error);
      return;
    }

    return data;
  }

  async getPrefix(projectId: number): Promise<string> {
    const { data, error } = await this.supabase.client
      .from('company_projects')
      .select('alt_prefix')
      .eq('id', projectId)
      .single();

    if (error) {
      throw new Error();
    }

    return data.alt_prefix;
  }

  setProjectColor(projectId: number, color: string) {
    return from(
      this.supabase.client
        .from('company_projects')
        .update({ color })
        .eq('id', projectId),
    );
  }

  createEpic(payload: Partial<Tables<'company_project_epics'>>) {
    return from(
      this.supabase.client
        .from('company_project_epics')
        .insert(payload)
        .select('*')
        .single(),
    );
  }

  updateEpic(payload: Partial<Tables<'company_project_epics'>>) {
    return from(
      this.supabase.client
        .from('company_project_epics')
        .update(payload)
        .eq('id', payload.id)
        .select('*')
        .single(),
    );
  }

  getEpic(epicId: number) {
    return from(
      this.supabase.client
        .from('company_project_epics')
        .select('*')
        .eq('id', epicId)
        .single(),
    ).pipe(
      map((response) => {
        if (!response) return null;

        return response.data as Tables<'company_project_epics'>;
      }),
    );
  }

  getTeamUsers(projectId: number) {
    return from(
      this.supabase.client
        .from('company_project_users')
        .select(SELECT_PROJECT_TEAM)
        .eq('company_project_id', projectId),
    ).pipe(
      map((response) => {
        if (!response.data) {
          return [];
        }

        return response.data;
      }),
      map((users) => {
        if (!users.length) {
          return [];
        }

        return users.map((user) => user.profile);
      }),
    );
  }

  loadProject(projectId: number) {
    console.log('[ProjectService]: Loading project ', projectId);
    this.loadingProject.set(true);
    this.onProjectChange$.next(null);

    return this.getProject(projectId).then((project) => {
      if (!project) return;
      this.workspaceService.selectWorkspace(project.company_id);
      this.onProjectChange$.next(project);
      return project;
    });
  }

  getTaskCount(projectId: number) {
    return from(
      this.supabase.client.rpc('get_project_task_count', {
        request_project_id: projectId,
      }),
    );
  }

  isManager(projectId: number) {
    return this.managedProjects$.pipe(
      first(),
      map((projects) => {
        const projectIds = projects.map((project) => project.id);
        return projectIds.includes(projectId);
      }),
    );
  }
  //#endregion

  //#region Dialogs
  showCreateProjectDialog() {
    const dialog = this.dialogService.open(CreateProjectDialogComponent, {
      panelClass: DEFAULT_PANEL_CLASSES,
      width: '552px',
    });

    dialog.closed.subscribe({
      next: (result) => {
        if (!result) return;
      },
    });
  }

  showEditProjectDialog(projectId: number) {
    this.getProject(projectId).then((project) => {
      const data: ICreateProjectDialogData = {
        project,
      };

      const dialog = this.dialogService.open(CreateProjectDialogComponent, {
        data,
        panelClass: DEFAULT_PANEL_CLASSES,
        width: '552px',
      });

      dialog.closed.pipe(take(1)).subscribe({
        next: (result) => {
          if (!result) return;

          this.onProjectChange$.pipe(take(1)).subscribe({
            next: (currentProject) => {
              if (
                !currentProject ||
                currentProject.id != (result as WorkspaceProject).id
              )
                return;
              this.onProjectChange$.next(result as WorkspaceProject);
            },
          });
        },
      });
    });
  }

  async showCreateEpicDialog(projectId: number | undefined = undefined) {
    // projectId = projectId || this.onProjectChange$.value?.id;

    // if (!projectId) {
    //   console.error('Project ID is required to create an epic');
    //   return;
    // }

    // const project = await this.getProject(projectId);

    // if (!project) {
    //   throw new Error('Project not found');
    // }

    const data: ICreateEpicDialogData = {
      projectId,
    };

    const dialog = this.dialogService.open(CreateEpicDialogComponent, {
      data,
      panelClass: DEFAULT_PANEL_CLASSES,
      width: '552px',
    });
  }

  async showEditEpicDialog(epicId: number) {
    const data: ICreateEpicDialogData = {
      epicId,
    };

    const dialog = this.dialogService.open(CreateEpicDialogComponent, {
      data,
      panelClass: DEFAULT_PANEL_CLASSES,
      width: '552px',
    });
  }

  showDeleteEpicDialog(epic: Tables<'company_project_epics'>) {
    // ! TODO: create dialog
    this.supabase.client
      .from('company_project_epics')
      .delete()
      .eq('id', epic.id)
      .then(() => {
        ServiceUtils.removeLocalEntityByPredicate(
          this.onEpicChanges$,
          (entity) => entity.id === epic.id,
        );
      });
  }

  showTeamDialog(projectId: number) {
    forkJoin([this.getProject(projectId), this.getTeamUsers(projectId)])
      .pipe(take(1))
      .subscribe({
        next: ([project, users]) => {
          if (!project) return;
          const data: ITeamDialogData = {
            project,
            users,
          };

          const dialog = this.dialogService.open(TeamDialogComponent, {
            data,
            panelClass: DEFAULT_PANEL_CLASSES,
            width: '552px',
            maxHeight: '90vh',
          });

          dialog.closed.pipe(take(1)).subscribe({
            next: (values) => {
              if (!values) return;
              const profiles = values as Profile[];
              const userIds = profiles.map((profile) => profile.id);

              this.updateTeamUsers(projectId, userIds).subscribe({
                next: () => {
                  this.refreshTeamUsers(projectId);
                },
              });
            },
          });
        },
      });
  }

  showConfirmProjectDeletionDialog(name: string) {
    const data: IConfirmDialogData = {
      title: `Confirm Project Deletion`,
      message: `
        <p>
          Are you sure you want to delete ${name} and all its content?
          <br />
          This action is <b>irreversible</b>, and all data associated with the project will be <b>permanently lost</b>. <br>Please confirm your decision to proceed.
        </p>
      `,
      hasReject: true,
      confirmButtonClass:
        'text-red-500 font-semibold hover:bg-red-500/10 hover:text-red-500',
      confirmButtonText: 'Delete',
      rejectButtonClass:
        'rounded-lg px-5 py-3 font-nunito-sans font-bold leading-[150%] text-spot-wireframe-700 transition-colors hover:bg-gray-500/10 active:bg-gray-500/25',
    };

    const dialog = this.dialogService.open(ConfirmDialogComponent, {
      width: '500px',
      data,
      panelClass: DEFAULT_PANEL_CLASSES,
    });

    return dialog.closed as Observable<boolean>;
  }
  //#endregion

  //#region Navigation
  goToProject(projectId: number, queryParams: any = {}) {
    this.router.navigate(['/', 'projects', projectId], {
      queryParams,
      queryParamsHandling: '',
    });
  }
  //#endregion
}
