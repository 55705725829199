import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { tablerX } from '@ng-icons/tabler-icons';
import { WorkspaceProject } from '@solid/types/project.types';
import { Profile } from '@solid/types/queries.types';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { take } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import { DialogBase } from '../../../../classes/dialog-base';
import { WorkspaceService } from '../../../../services/workspace.service';
import { ProjectService } from '../../../../services/project.service';

export interface ITeamDialogData {
  project: WorkspaceProject;
  users: Profile[];
}

@Component({
  selector: 'app-team-dialog',
  standalone: true,
  imports: [
    // third-party
    NgIconComponent,
    NzCheckboxModule,
    NzAvatarModule,
    NzToolTipModule,
  ],
  templateUrl: './team-dialog.component.html',
  host: {
    class:
      'grid grid-cols-1 grid-rows-[auto_1fr_auto] bg-white p-10 rounded-2xl shadow-md w-full h-full gap-6 min-h-[500px] max-h-[inherit]',
  },
  viewProviders: [
    provideIcons({
      tablerX,
    }),
  ],
})
export class TeamDialogComponent extends DialogBase {
  //#region Injections
  private data: ITeamDialogData = inject(DIALOG_DATA);
  private authService = inject(AuthService);
  private workspaceService = inject(WorkspaceService);
  private projectService = inject(ProjectService);
  //#endregion

  //#region Signals
  project = signal<WorkspaceProject>({} as WorkspaceProject);
  workspaceUsers = toSignal(this.workspaceService.userProfiles$);
  users = signal<Profile[]>([]);
  currentUser = toSignal(this.authService.user$);
  hasChanges = signal(false);
  //#endregion

  //#region Data Variables

  //#endregion

  constructor() {
    super();
    this.project.set(this.data.project);

    this.projectService.users$.pipe(take(1), takeUntilDestroyed()).subscribe({
      next: (users) => {
        if (!users || !users.length) {
          return;
        }

        this.users.set(users);
      },
    });
  }

  //#region Public Methods
  getInitials(name: string) {
    return name
      .split(' ')
      .map((n) => n.charAt(0))
      .join('');
  }
  //#endregion

  //#region Private Methods
  private addUser(user: Profile) {
    this.users.update((users) => [...users, user]);
  }

  private removeUser(user: Profile) {
    this.users.update((users) => users.filter((u) => u.id !== user.id));
  }
  //#endregion

  //#region Public Methods
  isChecked(userId: string) {
    return this.users().some((u) => u.id === userId);
  }
  //#endregion

  //#region Handlers
  hndToggleUserState(checked: boolean, user: Profile) {
    this.hasChanges.set(true);

    if (checked) {
      this.addUser(user);
      return;
    }

    this.removeUser(user);
  }

  hndSave() {
    this.dialogRef.close(this.users());
  }
  //#endregion
}
