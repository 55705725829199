import { DEFAULT_PROJECT_COLORS } from '@solid/constants/project.constant';
import TextUtils from './text.utils';

export default class ProjectUtils {
  static generatePrefix(name: string, existingPrefixes: string[]) {
    const MAX_LENGTH = 10;
    const PREFERRED_LENGTH = 4;
    let result = '';

    const nameParts = name.trim().split(' ');

    if (nameParts.length > 1) {
      nameParts.forEach((word) => (result += word.charAt(0)));

      let currentIndex = 0;
      let currentIndexSuffix = 1;

      while (existingPrefixes.includes(result)) {
        const isLastIndex = currentIndex === nameParts.length - 1;
        const currentWord = nameParts.at(currentIndex)!;

        if (currentWord.length <= PREFERRED_LENGTH) {
          result = nameParts.at(currentIndex)!.toUpperCase();
        } else {
          result = nameParts
            .at(currentIndex)!
            .toUpperCase()
            .substring(0, PREFERRED_LENGTH);
        }

        if (isLastIndex && existingPrefixes.includes(result)) {
          result += currentIndexSuffix;
          currentIndexSuffix += 1;
        } else {
          currentIndex += 1;
        }
      }
    } else if (nameParts.length === 1) {
      const word = nameParts
        .at(0)!
        .toUpperCase()
        .substring(0, PREFERRED_LENGTH);

      result = word;

      let currentIndexSuffix = 1;
      while (existingPrefixes.includes(result)) {
        result = word + currentIndexSuffix;
        currentIndexSuffix += 1;
      }
    }

    return result;
  }

  static generateColor(name: string) {
    const charCodeSum = name
      .split('')
      .reduce(
        (accumulator, current) => (accumulator += current.charCodeAt(0)),
        0,
      );

    const defaultColorsLength = DEFAULT_PROJECT_COLORS.length;
    const colorIndex = charCodeSum % defaultColorsLength;
    const color = DEFAULT_PROJECT_COLORS.at(colorIndex);

    return color!;
  }

  static getUrl(projectId: number) {
    const url = new URL(`/projects/${projectId}`, window.location.origin);

    return url.toString();
  }
}
