import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { tablerLoader2, tablerX } from '@ng-icons/tabler-icons';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { DialogBase } from '../../../../classes/dialog-base';
import { SolidFocusDirective } from '../../../directives/focus/focus.directive';
import { EpicService } from '../../../../services/epic.service';
import { ProjectService } from '../../../../services/project.service';
import { Epic } from '../../../../types/project.types';
import { Enums } from '../../../../types/supabase';
import { finalize } from 'rxjs';
import { SolidButtonDirective } from '../../../directives/button/button.directive';
import { NgxPermissionsModule } from 'ngx-permissions';

export interface ICreateEpicDialogData {
  projectId?: number;
  epicId?: number;
}

@Component({
  selector: 'app-create-epic-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AsyncPipe,

    // own
    SolidFocusDirective,

    // directives
    SolidButtonDirective,

    // third-party
    NgIconComponent,
    NzInputModule,
    NzSelectModule,
    NzDatePickerModule,
    NgxPermissionsModule,
  ],
  templateUrl: './create-epic-dialog.component.html',
  host: {
    class:
      'relative flex flex-col bg-white p-10 rounded-2xl shadow-md w-full h-full gap-5 overflow-hidden',
  },
  viewProviders: [
    provideIcons({
      tablerX,
      tablerLoader2,
    }),
  ],
})
export class CreateEpicDialogComponent extends DialogBase {
  //#region Injections
  private data: ICreateEpicDialogData = inject(DIALOG_DATA);
  private projectService = inject(ProjectService);
  private epicService = inject(EpicService);
  //#endregion

  //#region Data Variables
  epic = signal<Epic | undefined>(undefined);
  projects = toSignal(this.projectService.projects$);
  managedProjects = toSignal(this.projectService.managedProjects$);
  //#endregion

  //#region Control Variables
  isNew = signal(this.data?.epicId == undefined);
  hasProjectId = computed(() => {
    const isNew = this.isNew();
    const dataHasProjectId = this.data?.projectId != undefined;

    return !isNew || dataHasProjectId;
  });
  loading = signal(true);
  //#endregion

  //#region Data Variables
  form = new FormGroup({
    id: new FormControl(this.data?.epicId ?? undefined),
    project_id: new FormControl(this.data?.projectId, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    priority: new FormControl<Enums<'priority'>>('medium', [
      Validators.required,
    ]),
    description: new FormControl(''),
    start_at: new FormControl<Date | null>(null),
    end_at: new FormControl<Date | null>({
      disabled: true,
      value: null,
    }),
  });
  //#endregion

  //#region Signals
  currentEpicName = signal<string>('');
  //#endregion

  constructor() {
    super();

    effect(() => {
      const epic = this.epic();
      if (!epic) return;

      this.updateForm(epic);
    });

    if (!this.isNew()) {
      this.getEpic(this.data.epicId!)
        .pipe(finalize(() => this.loading.set(false)))
        .subscribe({
          next: (epic) => {
            if (!epic) return;
            this.epic.set(epic);
          },
        });
    } else {
      this.loading.set(false);
    }

    this.form.controls.start_at.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (value) => {
          const endAtControl = this.form.controls.end_at;

          if (!value) {
            endAtControl.disable();
            endAtControl.setValue(null);
            return;
          }

          endAtControl.enable();

          if (
            endAtControl.value &&
            (endAtControl.value < value || endAtControl.value == value)
          ) {
            endAtControl.setValue(null);
          }
        },
      });
  }

  //#region Private Methods
  private getEpic(epicId: number) {
    return this.epicService.get(epicId);
  }

  private updateForm(epic: Epic) {
    const endAtControl = this.form.controls.end_at;

    const startAt = epic?.start_at ? new Date(epic?.start_at) : null;
    const endAt = epic?.end_at ? new Date(epic?.end_at) : null;
    const disableEndAt = startAt == undefined;

    if (disableEndAt) {
      endAtControl.disable();
    } else {
      endAtControl.enable();
    }

    this.form.patchValue({
      project_id: epic.project_id,
      name: epic.name,
      priority: epic.priority,
      description: epic.description,
      start_at: startAt,
      end_at: endAt,
    });
  }

  private update(epic: Partial<Epic>) {
    return this.epicService.update(epic);
  }

  private create(epic: Partial<Epic>) {
    return this.epicService.add(epic);
  }
  //#endregion

  //#region Public Methods
  isDateDisabled = (current: Date): boolean => {
    const { start_at } = this.form.value;

    if (!start_at) {
      return true;
    }

    return current < start_at;
  };
  //#endregion

  //#region Handlers
  hndSave() {
    if (this.form.invalid || !this.form.dirty) {
      return;
    }

    const data = this.form.getRawValue() as Partial<Epic>;
    const observable = this.isNew() ? this.create(data) : this.update(data);

    observable.subscribe({
      next: (result) => {
        if (!result) {
          return;
        }

        this.dialogRef.close(result);
      },
    });
  }
  //#endregion
}
