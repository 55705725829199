import { A11yModule } from '@angular/cdk/a11y';
import { DIALOG_DATA, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { tablerX } from '@ng-icons/tabler-icons';
import { DEFAULT_METHODOLOGY } from '@solid/constants/project.constant';
import { Enums, Tables } from '@solid/types/supabase';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { DialogBase } from '../../../../classes/dialog-base';
import { WorkspaceService } from '../../../../services/workspace.service';
import { ProjectService } from '../../../../services/project.service';
import {
  WorkspaceProject,
  CreateProjectPayload,
} from '../../../../types/project.types';
import ProjectUtils from '../../../../utils/project.utils';
import { SolidButtonDirective } from '../../../directives/button/button.directive';
import { SolidFocusDirective } from '../../../directives/focus/focus.directive';
import { SolidSeparatorComponent } from '../../separator/separator.component';

export interface ICreateProjectDialogData {
  project: WorkspaceProject | undefined;
}

@Component({
  selector: 'app-create-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // cdk
    A11yModule,
    DialogModule,
    // directives
    SolidFocusDirective,

    // components
    SolidSeparatorComponent,
    SolidButtonDirective,

    // third party
    NgIconComponent,
    NzInputModule,
    NzSliderModule,
    NzSelectModule,
    NzInputNumberModule,
  ],
  templateUrl: './create-project-dialog.component.html',
  host: {
    class:
      'flex flex-col bg-white p-10 rounded-2xl shadow-md w-full h-full gap-6 min-h-[500px]',
  },
  viewProviders: [
    provideIcons({
      tablerX,
    }),
  ],
})
export class CreateProjectDialogComponent extends DialogBase {
  //#region Injections
  private data: ICreateProjectDialogData | undefined = inject(DIALOG_DATA);
  private workspaceService = inject(WorkspaceService);
  private projectService = inject(ProjectService);
  //#endregion

  //#region Data Variables
  project = signal<WorkspaceProject | undefined>(undefined);
  usedPrefixes = toSignal(this.projectService.getPrefixes(), {
    initialValue: [],
  });
  //#endregion

  //#region Observables
  workspace$ = this.workspaceService.workspace$;
  users$ = this.workspaceService.userProfiles$;
  //#endregion

  //#region Control Variables
  isNew = true;
  //#endregion

  //#region Data Variables
  form: FormGroup = new FormGroup({
    id: new FormControl<number | null>(this.data?.project?.id ?? null),
    name: new FormControl<string>(this.data?.project?.name ?? '', [
      Validators.required,
    ]),
    description: new FormControl<string>(this.data?.project?.description ?? ''),
    manager_id: new FormControl<string | null>(
      this.data?.project?.manager_id ?? null,
      [Validators.required],
    ),
    company_id: new FormControl<number | null>(null),
    budget: new FormControl<number>(this.data?.project?.budget ?? 0),
    alt_prefix: new FormControl<string>(this.data?.project?.alt_prefix ?? '', [
      Validators.required,
      Validators.maxLength(10),
    ]),
    methodology: new FormControl<Enums<'agile_methodology'>>(
      this.data?.project?.methodology ?? DEFAULT_METHODOLOGY,
    ),
  });
  //#endregion

  constructor() {
    super();

    if (this.data?.project) {
      this.isNew = false;
      this.project.set(this.data.project);
    }

    if (this.isNew) {
      this.workspace$.pipe(takeUntilDestroyed()).subscribe({
        next: (workspace) => {
          if (!workspace) {
            return;
          }

          this.form.get('company_id')?.setValue(workspace.id, {
            emitEvent: false,
          });
        },
      });

      this.form
        .get('name')
        ?.valueChanges.pipe(takeUntilDestroyed())
        .subscribe({
          next: (name) => {
            const suggestedPrefix = ProjectUtils.generatePrefix(
              name,
              this.usedPrefixes(),
            );

            this.form.patchValue(
              {
                alt_prefix: suggestedPrefix,
              },
              {
                emitEvent: false,
              },
            );
          },
        });
    }
  }

  //#region Private Methods

  hndSave() {
    if (!this.form.valid) {
      return;
    }

    const rawValues = this.form.getRawValue();

    if (this.isNew) {
      const formValues = this.form.value;
      delete formValues.id;

      const payload: CreateProjectPayload = formValues;
      payload.color = ProjectUtils.generateColor(payload.name);
      this.projectService.create(payload).then((data) => {
        if (!data) {
          return;
        }

        this.dialogRef.close(data);
      });
      return;
    }

    const project = this.data?.project;
    if (!project) return;

    const payload: Tables<'company_projects'> = {
      ...project,
      ...rawValues,
    };

    this.projectService.update(payload).then((data) => {
      this.dialogRef.close(data);
    });
  }
  //#endregion
}
