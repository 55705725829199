<div class="flex items-center justify-between">
  <h4>Edit {{ project().name }}'s team</h4>
  <button
    class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-md text-gray-500 outline-gray-500 transition-colors hover:bg-gray-500/10 hover:text-gray-600 active:bg-gray-500/25 active:text-gray-700"
    (click)="hndClose()"
  >
    <ng-icon name="tablerX" size="20" strokeWidth="2"></ng-icon>
  </button>
</div>

<div
  class="flex flex-1 flex-col gap-1 overflow-y-auto overflow-x-hidden pr-2 scrollbar-thin scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500 active:scrollbar-thumb-gray-600"
>
  @for (user of workspaceUsers(); track $index) {
    <div class="flex items-center gap-2 py-2">
      <nz-avatar
        [nzSrc]="user.avatar_url"
        [nzText]="getInitials(user.full_name)"
      ></nz-avatar>
      <div class="text-text-primary">{{ user.full_name }}</div>
      <label
        class="ml-auto"
        nz-checkbox
        [nzDisabled]="user.id == currentUser()?.id && isChecked(user.id)"
        [nz-tooltip]="
          user.id == currentUser()?.id && isChecked(user.id)
            ? 'You cannot remove yourself'
            : ''
        "
        [nzChecked]="isChecked(user.id)"
        (nzCheckedChange)="hndToggleUserState($event, user)"
      ></label>
    </div>
  }
</div>

<div class="mt-auto flex justify-between gap-6">
  <button
    class="rounded-lg px-5 py-3 font-nunito-sans font-bold leading-[150%] text-spot-wireframe-700 transition-colors hover:bg-gray-500/10 active:bg-gray-500/25"
    (click)="hndClose()"
  >
    Cancel
  </button>
  <!-- <button
    class="rounded-lg bg-primary px-5 py-3 font-nunito-sans font-bold leading-[150%] text-white transition-colors active:bg-spot-purple-800 disabled:bg-primary/75"
    [disabled]="form.invalid || !form.dirty"
    (click)="hndSave()"
  >
    Create project
  </button> -->
  <button
    class="rounded-lg bg-primary px-5 py-3 font-nunito-sans font-bold leading-[150%] text-white transition-colors active:bg-spot-purple-800 disabled:bg-primary/75"
    [disabled]="!hasChanges()"
    (click)="hndSave()"
  >
    Save team
  </button>
</div>
